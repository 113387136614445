<template>
  <div>
    <el-card style="margin-bottom: 10px">
      <el-form ref="queryForRef" :model="queryForm" label-width="80px">
        <el-form-item label="姓名：">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="queryForm.name"
          ></el-input>
        </el-form-item>

        <el-form-item label="电话：">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="queryForm.phone"
          ></el-input>
        </el-form-item>

                <el-form-item label="地址：">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="queryForm.djs"
          ></el-input>
        </el-form-item>
        <el-form-item>



          <el-button
            type="primary"
            @click="
              () => {
                (pageNum = 1), queryHouse();
              }
            "
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table
        :data="houseList"
        height="500"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="ID" type="index" width="50"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column :formatter="formatTime" prop="pjsj" label="配镜时间">
        </el-table-column>
        <el-table-column prop="gender" label="性别"> </el-table-column>
        <el-table-column prop="ygs" label="顾客电话"> </el-table-column>

        <el-table-column prop="dictTime" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editForm1(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteHouse(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 20px"
        background
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 25]"
        @current-change="pageNumChange"
        @size-change="pageSizeChange"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="编辑房源" :visible.sync="editFormVisible">
      <el-form :model="editForm">
        <el-form-item label="配镜时间：" prop="pjsj">
          <el-date-picker
            format="yyyy-M-d"
            v-model="addForm.pjsj"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="姓名：" prop="name">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.name"
            placeholder="请输入顾客姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="性别：" prop="gender">
          <el-radio v-model="addForm.gender" label="男">男</el-radio>
          <el-radio v-model="addForm.gender" label="女">女</el-radio>
        </el-form-item>

        <el-form-item label="年龄：" prop="age">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.age"
          ></el-input>
        </el-form-item>

         <el-form-item label="是否为会员" prop="ishy">
        <el-radio v-model="addForm.ishy" label="是">是</el-radio>
        <el-radio v-model="addForm.ishy" label="否">否</el-radio>
      </el-form-item>

            <el-form-item label="卡内积分：" prop="knjf">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.knjf"
        ></el-input>
      </el-form-item>

      
      <el-form-item label="卡内余额：" prop="knye">
        <el-input
          style="width: 180px"
          size="mini"
          v-model="addForm.knye"
        ></el-input>
      </el-form-item>

        <el-form-item label="运用类型：" prop="applicationType">
          <el-radio v-model="addForm.applicationType" label="近用"
            >近用</el-radio
          >
          <el-radio v-model="addForm.applicationType" label="远用"
            >远用</el-radio
          >
        </el-form-item>

        <el-form-item label="配镜处方：" prop="pjcf">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            :cell-style="rowStyle"
          >
            <el-table-column
              prop="pjcf"
              label="配镜处方"
              header-align="center"
            ></el-table-column>
            <el-table-column prop="qj" label="球镜(SPH)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="qj">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.qj"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="zj" label="柱镜(CYL)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="zj">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zj"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="zw" label="轴位(AX)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="zw">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zw"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="tj" label="瞳距(mm)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="tj">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.tj"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="tg" label="瞳高(mm)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="tg">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.tg"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="xjg"
              label="下加光(Add)"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-form-item prop="xjg">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.xjg"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="jzsl" label="矫正视力" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="jzsl">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.jzsl"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>

          <el-table
            :data="tableDatas"
            border
            style="width: 100%"
            :cell-style="rowStyle"
          >
            <el-table-column
              prop="pjcf"
              label="配镜处方"
              header-align="center"
            ></el-table-column>

            <el-table-column prop="zqj" label="球镜(SPH)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="zqj">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zqj"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="zzj" label="柱镜(CYL)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="zzj">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zzj"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="zzw" label="轴位(AX)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="zzw">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zzw"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="lefttj"
              label="瞳距(mm)"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-form-item prop="lefttj">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.lefttj"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="ztg" label="瞳高(mm)" header-align="center">
              <template slot-scope="scope">
                <el-form-item prop="ztg">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.ztg"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="zxjg"
              label="下加光(Add)"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-form-item prop="zxjg">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zxjg"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="zjzsl"
              label="矫正视力"
              header-align="center"
            >
              <template slot-scope="scope">
                <el-form-item prop="zjzsl">
                  <el-input
                    style="width: 100px"
                    size="mini"
                    v-model="addForm.zjzsl"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item label="总瞳距：" prop="ztj">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.ztj"
            placeholder="请输入顾客总瞳距"
          ></el-input>
        </el-form-item>

        <el-form-item label="顾客电话：" prop="ygs">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.ygs"
            placeholder="请输入顾客电话"
          ></el-input>
        </el-form-item>

        <el-form-item label="镜架名称：" prop="jjmc">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.jjmc"
            placeholder="请输入镜架名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="镜片名称：" prop="jpmc">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.jpmc"
            placeholder="请输入镜片名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="顾客卡号：" prop="gkkh">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.gkkh"
            placeholder="请输入顾客卡号"
          ></el-input>
        </el-form-item>

        <el-form-item label="地址：" prop="gkdjs">
          <el-input
            style="width: 180px"
            size="mini"
            v-model="addForm.gkdjs"
            placeholder="请输入顾客地址"
          ></el-input>
        </el-form-item>

        <el-row>
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-form-item label="原镜片价格：" prop="yjpjg">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="addForm.yjpjg"
                  placeholder="请原镜片价格"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="单位：" prop="yjpdw">
                <el-input
                  style="width: 50px"
                  size="mini"
                  v-model="addForm.yjpdw"
                ></el-input
                >付/对
              </el-form-item>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="优惠折扣：" prop="jpdz">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="addForm.jpdz"
                  placeholder="请输入优惠价格"
                ></el-input>
              </el-form-item></div
          ></el-col>

          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="优惠后价格：" prop="yjyhjg">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="jpsum"
                  placeholder="请输入优惠价格"
                ></el-input>
              </el-form-item></div
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <el-form-item label="原镜架价格：" prop="yjjjg">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="addForm.yjjjg"
                  placeholder="请原镜片价格"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="单位：" prop="jjdw">
                <el-input
                  style="width: 50px"
                  size="mini"
                  v-model="addForm.jjdw"
                ></el-input
                >付/对
              </el-form-item>
            </div></el-col
          >

          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="优惠折扣：" prop="jjdz">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="addForm.jjdz"
                  placeholder="请输入优惠价格"
                ></el-input>
              </el-form-item></div
          ></el-col>

          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <el-form-item label="优惠后价格：" prop="jjjg">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="inputFunc"
                  @input="inputFunc()"
                  placeholder="请输入优惠后价格"
                ></el-input>
              </el-form-item></div
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <el-form-item label="应收金额：" prop="ysje">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="sum"
                  @input="sum()"
                  placeholder="请输入优惠后价格"
                ></el-input>
              </el-form-item></div
          ></el-col>

          <el-col :span="6"
            ><div class="grid-content bg-purple-dark">
              <el-form-item label="实收金额：" prop="ssje">
                <el-input
                  style="width: 180px"
                  size="mini"
                  v-model="addForm.ssje"
                  placeholder="请输入优惠后价格"
                ></el-input>
              </el-form-item></div
          ></el-col>
        </el-row>

        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="addForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          pjcf: "右（R）",
        },
      ],
      tableDatas: [
        {
          pjcf: "左（L）",
        },
      ],
      value1: "",
      editFormVisible: false,
      value: "",
      houseList: [] /* 列表数据 */,
      pageNum: 1,
      pageSize: 5,
      total: 0,
      queryForm: {
        phone: "",
        djs: "",
        chaoxiang: [],
        province: "",
        city: "",
        area: "",
        rentMode: "",
        huxing: [],
        fangshi: [],
        zujin: [],
      },

      area: {
        provinceList: [],
        cityList: [],
        areaList: [],
      },

      dict: {
        rentModeNameList: [],
        houseTypeList: [],
        directionList: [],
        renealList: [],
      },
      editForm: {
        id: "",
        province: "",
        city: "",
        area: "",
        fangshi: "",
        huxing: "",
        chaoxiang: "",
        info: "",
        zujin: "",
      },

      addForm: {
        ishy: "",
        knye: "",
        knjf: "",
        pjsj: "",
        name: "",
        gender: "",
        age: "",
        occupation: "",
        rentMode: "",
        pjcf: "",
        qj: "",
        zj: "",
        zw: "",
        tj: "",
        tg: "",
        xjg: "",
        jzsl: "",
        ztj: "",
        ygs: "",
        jjmc: "",
        jpmc: "",
        pjlx: "",
        pjcl: "",
        pjys: "",
        pjzsl: "",
        pjmc: "",
        gkkh: "",
        gkdjs: "",
        gksr: "",
        yjpjg: "",
        yjpdw: "",
        jpdz: "",
        yjyhjg: "",
        yjjjg: "",
        jjdw: "",
        jjdz: "",
        jjjg: "",
        ysje: "",
        ssje: "",
        remark: "",
        applicationType: "",
        zqj: "",
        zzj: "",
        zzw: "",
        lefttj: "",
        ztg: "",
        zxjg: "",
        zjzsl: "",
      },

      editArea: {
        cityList: [],
        areaList: [],
      },
    };
  },

  computed: {
    sum() {
      return isNaN(this.inputFunc * this.jpsum)
        ? 0
        : parseFloat(this.inputFunc) + parseFloat(this.jpsum);
    },
    inputFunc() {
      return isNaN(
        parseFloat(this.addForm.yjjjg) * (parseFloat(this.addForm.jjdz) * 0.1)
      )
        ? 0
        : parseFloat(
            parseFloat(this.addForm.yjjjg) *
              (parseFloat(this.addForm.jjdz) * 0.1)
          ).toFixed(1);
    },
    jpsum() {
      return isNaN(
        parseFloat(this.addForm.yjpjg) * (parseInt(this.addForm.jpdz) * 0.1)
      )
        ? 0
        : parseFloat(
            parseFloat(this.addForm.yjpjg) *
              (parseFloat(this.addForm.jpdz) * 0.1)
          ).toFixed(1);
    },
  },
  methods: {
    rowStyle() {
      return "text-align:center";
    },
    formatTime(row, column) {
      let data = row[column.property];
      let dtime = new Date(data);
      const year = dtime.getFullYear();
      let month = dtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = dtime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let hour = dtime.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = dtime.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let second = dtime.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },

    deleteHouse(id) {
      alert("确定删除吗");
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 1000);

      this.$http
        .post("/gk/delete/" + id)
        .then((resp) => {
          if (resp.data.code === 200) {
            this.queryHouse();
          } else {
            this.$message.error(resp.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    chongzhi() {
      this.$refs.queryForRef.resetField;
    },
    submitUpdate() {
      this.$http
        .post("/add", this.addForm)
        .then((resp) => {
          console.log(resp);
          if (resp.data.code === 200) {
            alert("添加成功");
            this.queryHouse();
            this.editFormVisible = false;
          } else {
            alert("失败");
            this.queryHouse();
            this.editFormVisible = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    editForm1(house) {
      this.editForm.id = house.id;
      this.editFormVisible = true;
      this.$http
        .post("/gk/" + house.id)
        .then((resp) => {
          this.addForm = resp.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    queryFS() {
      this.$http
        .get("/dict/rent_mode")
        .then((resp) => {
          if (resp.data.code == 200) {
            this.dict.rentModeNameList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    queryZJ() {
      this.$http
        .get("/dict/rental")
        .then((resp) => {
          if (resp.data.code == 200) {
            this.dict.renealList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    queryHX() {
      this.$http
        .get("/dict/house_type")
        .then((resp) => {
          if (resp.data.code == 200) {
            this.dict.houseTypeList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    queryCX() {
      this.$http
        .get("/dict/direction")
        .then((resp) => {
          if (resp.data.code == 200) {
            this.dict.directionList = resp.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    queryProvince() {
      this.$http
        .get("/area/-1")
        .then((resp) => {
          if (resp.data.code == 200) {
            this.area.provinceList = resp.data.data;
            console.log(resp.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    queryCity(provinceId) {
      if (provinceId) {
        this.$http
          .get("/area/" + provinceId)
          .then((resp) => {
            if (resp.data.code == 200) {
              this.area.cityList = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.queryForm.city = "";
      this.queryForm.area = "";
      this.area.cityList = [];
      this.area.areaList = [];
    },

    queryArea(cityId) {
      this.queryForm.area = "";
      if (cityId) {
        this.$http
          .get("/area/" + cityId)
          .then((resp) => {
            if (resp.data.code == 200) {
              this.area.areaList = resp.data.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    tableRowClassName({ row, rowIndex }) {
      if (row.rentModeName === "整租") {
        return "warning-row";
      } else if (row.rentModeName === "合租") {
        return "success-row";
      }
      return "";
    },

    queryHouse() {
      this.$http
        .get("/queryGk", {
          params: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            name: this.queryForm.name,
            phone: this.queryForm.phone,
            djs: this.queryForm.djs
          },
        })
        .then((resp) => {
          this.houseList = resp.data.data;
          this.total = resp.data.data.total;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //页码改变时触发
    pageNumChange(currentPage) {
      this.pageNum = currentPage;
      this.queryHouse();
    },

    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.queryHouse();
    },
  },
  created() {
    this.queryHouse();
  },
};
</script>

<style>
.el-table .warning-row {
  background: rgb(208, 241, 206);
}

.el-table .success-row {
  background: #f5c8bc;
}
</style>